<template>
	<WwSideScroller
		class="flex flex-wrap justify-start w-full m-auto justify-items-center"
		override-default-styles
		:item-spacing="itemSpacing"
		:scroller-class="stylesByViewType"
	>
		<div
			v-for="( card, index ) in newList"
			:key="`${card.title}-${index}-new`"
			ref="searchResultItem"
			v-track-event:custom.search.click="isSearchInput ? customTracker : null"
			:class="[
				{[HIGHLIGHTED_CLASS]: isSearchInput && index === highlightedIndex},
				{'hover:bg-gray-200 px-3.5 hover:shadow-inner': isSearchInput}
			]"
			@click="handleSearchResultClick"
		>
			<CurrentLocation
				v-if="card.listType === CURRENT_LOCATION && locationOnly"
				class="flex items-center text-base text-left mx-auto py-2.5 hover:bg-gray-200"
			/>
			<SearchCardStrain
				v-if="card.listType === STRAINS_LIST_FULL"
				:result="card"
				:is-mobile="isMobile"
				:list-type="card.listType"
				:layout-type="layoutType"
			/>
			<SearchCardStrainCompact
				v-if="card.listType === STRAINS_LIST_COMPACT"
				:result="card"
				:is-mobile="isMobile"
				:list-type="card.listType"
			/>
			<SearchCardBusiness
				v-if="card.listType === BUSINESSES_LIST"
				:result="card"
				:is-mobile="isMobile"
				:list-type="card.listType"
				is-autocompleter
			/>
			<SearchCardMenu
				v-if="card.listType === MENUS_LIST"
				:result="card"
				:is-mobile="isMobile"
				:list-type="card.listType"
			/>
			<div class="-mx-2">
				<SearchCardLocation
					v-if="card.listType === LOCATION"
					:result="card"
					:is-mobile="isMobile"
					:list-type="card.listType"
				/>
			</div>
			<SearchCardBlog
				v-if="card.listType === BLOGS_LIST"
				:result="card"
				:is-mobile="isMobile"
				:list-type="card.listType"
			/>
			<SearchCardDeal
				v-if="card.listType === DEALS_LIST"
				:result="card"
				:is-mobile="isMobile"
				:list-type="card.listType"
			/>
		</div>
	</WwSideScroller>
</template>

<script async>
import { mapActions, mapGetters } from 'vuex'

import destructureSearchResult from '@/api/helpers/destructureSearchResult.js'
import WwSideScroller from '@/components/UI/WwSideScroller.vue'
import { SEARCH_TERM_CUSTOM_EVENT, SEARCH_TERM_CUSTOM_MAP } from '@/constants/logging/customEvents.js'
import { HIGHLIGHTED_CLASS } from '@/constants/search/index.js'
import { FLEX_COL, GRID, INLINE } from '@/constants/search/layoutTypes.js'
import {
	BLOGS_LIST,
	BUSINESSES_LIST,
	DEALS_LIST,
	GLOBAL_LIST,
	LOCATIONS_LIST,
	MENUS_LIST,
	STRAINS_LIST,
	STRAINS_LIST_COMPACT,
	STRAINS_LIST_FULL
} from '@/constants/search/listTypes.js'
import { BUSINESS, CURRENT_LOCATION, LOCATION, MENU, STRAIN } from '@/constants/search/resultTypes.js'

export default {
	components: {
		CurrentLocation: () => import('@/components/search/SearchCardCurrentLocation.vue'),
		SearchCardStrain: () => import('@/components/search/SearchCardStrain.vue'),
		SearchCardStrainCompact: () => import('@/components/search/SearchCardStrainCompact.vue'),
		SearchCardBusiness: () => import('@/components/search/SearchCardBusiness.vue'),
		SearchCardBlog: () => import('@/components/search/SearchCardBlog.vue'),
		SearchCardMenu: () => import('@/components/search/SearchCardMenu.vue'),
		SearchCardLocation: () => import('@/components/search/SearchCardLocation.vue'),
		SearchCardDeal: () => import('@/components/search/SearchCardDeal.vue'),
		WwSideScroller
	},
	props: {
		items: {
			type: Array,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		compact: {
			type: Boolean,
			default: false
		},
		layoutType: {
			type: String,
			validator (value) {
				return [ GRID, INLINE, FLEX_COL ].includes(value)
			},
			default: GRID
		},
		isSearchInput: {
			type: Boolean,
			default: false
		},
		gapClasses: {
			type: String,
			default: 'gap-6'
		},
		marginClasses: {
			type: String,
			default: ''
		},
		gridColClasses: {
			type: String,
			default: 'grid-cols-3 xl:grid-cols-5'
		},
		locationOnly: {
			type: Boolean,
			default: false
		},
		enableAccessibilityScroll: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'search-result-clicked' ],
	data() {
		return {
			STRAIN,
			GLOBAL_LIST,
			STRAINS_LIST,
			STRAINS_LIST_FULL,
			STRAINS_LIST_COMPACT,
			BUSINESSES_LIST,
			BLOGS_LIST,
			MENUS_LIST,
			DEALS_LIST,
			LOCATION,
			INLINE,
			GRID,
			FLEX_COL,
			HIGHLIGHTED_CLASS,
			CURRENT_LOCATION
		}
	},
	computed: {
		itemSpacing() {
			switch (this.layoutType) {
				case INLINE:
					return 'space-x-6'
				default:
					return 'space-x-0'
			}
		},
		newList() {
			const tempArray = []

			if (this.items && this.items.length) {
				for (const index in this.items) {
					const tempItem = this.items[index]
					const tempCategory = this.setResultListType(this.listType, tempItem.type)
					tempArray.push(destructureSearchResult(tempItem, tempCategory))
				}
				return tempArray
			} else {
				return []
			}
		},
		stylesByViewType() {
			let defaultStyles = ''
			if (!this.isMobile) {
				defaultStyles = `${this.marginClasses} grid ${this.gridColClasses} ${this.gapClasses} justify-items-center`
			}
			switch (this.layoutType) {
				case GRID:
					return defaultStyles
				case INLINE:
					return 'w-full flex flex-nowrap overflow-x-auto mx-0 justify-start pb-4 overflow-y-hidden'
				case FLEX_COL:
					return 'flex flex-col'
				default:
					return defaultStyles
			}
		},
		...mapGetters('search', [ 'highlightedIndex' ]),
		customTracker() {
			return {
				customMap: SEARCH_TERM_CUSTOM_MAP,
				event: SEARCH_TERM_CUSTOM_EVENT
			}
		}
	},
	watch: {
		highlightedIndex: {
			immediate: true,
			handler(newIndex) {
				if (newIndex < 0) return
				const highlightedElement = this.$refs.searchResultItem?.[newIndex]
				if (highlightedElement && this.enableAccessibilityScroll) {
					highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
				}
				const highlightedItem = this.newList[newIndex]
				if (highlightedItem) { this.setHighlightedRoute(highlightedItem.link) }
			}
		}
	},
	methods: {
		setResultListType(listType, itemType) {
			switch (listType) {
				case MENUS_LIST || MENU:
					return MENUS_LIST
				case STRAINS_LIST || STRAIN:
					return STRAINS_LIST_FULL
				case LOCATIONS_LIST || LOCATION:
					return LOCATIONS_LIST
				case BUSINESSES_LIST:
					return BUSINESSES_LIST
				case BLOGS_LIST:
					return BLOGS_LIST
				case DEALS_LIST:
					return DEALS_LIST
				case GLOBAL_LIST:
					return this.forceCompactCardView(itemType)
				default:
					return STRAINS_LIST_FULL
			}
		},
		forceCompactCardView(type) {
			switch (type) {
				case STRAIN:
					return STRAINS_LIST_COMPACT
				case LOCATION:
					return LOCATION
				case MENU:
					return MENUS_LIST
				case BUSINESS:
					return BUSINESSES_LIST
				case CURRENT_LOCATION:
					return CURRENT_LOCATION
				default:
					return STRAINS_LIST_FULL
			}
		},
		...mapActions('search', [ 'setHighlightedRoute' ]),
		handleSearchResultClick() {
			if (this.isSearchInput) this.$emit('search-result-clicked')
		}
	}
}
</script>
